<template>
  
    <div
      class="flex flex-col h-full px-8 py-6 bg-gray-50"
    >
      <header class="flex justify-between">
        <div class="w-full">
          <h1 class="sr-only">Policies</h1>
          <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">
            Policies
          </h1>
          <p class="text-sm font-normal text-gray-600 w-5/6 my-2">
            Policies are Preava-defined configurations that trigger a safe, warn, or block action when anomalous email recipients, and sensitive, regulated, and proprietary data is detected in <em class="italic">outbound</em> emails.
          </p>
          <div>
            <!-- <span class="bg-gray-300 text-gray-50 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border">CANDIDATE</span> -->
            <!-- <span class="bg-orange-50 text-orange-500 border-orange-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border">ALPHA</span>
            <span class="bg-purple-50 text-purple-500 border-purple-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border">BETA</span>
            <span class="bg-red-50 text-red-500 border-red-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border">EXPERIMENTAL</span>
            <span class="bg-blue-50 text-blue-500 border-blue-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border">UPCOMING</span> -->
          </div>
        </div>
      </header>
      <main
        :class="[
          SESSION_STORE.isWaiting ? 'pointer-events-none opacity-50' : '',
        ]"
      >
      <div v-if="SESSION_STORE.isInitializing" class="mt-8 flex justify-center">
        
        <LoaderIcon class="animate-spin flex-shrink-0 mr-2 h-4 w-4 text-gray-400"/>
        
      </div>
      <div v-else>

        <!-- Tab Menu -->
        <div class="h-9 my-2 flex justify-between items-end space-x-4">
          <div class="text-sm font-normal text-gray-600">
            Showing {{ sortedPolicies.length }} {{ sortedPolicies.length > 1 ? 'Policies' : 'Policy' }}
          </div>
          <div class="flex flex-wrap text-right space-x-2">
            <div v-if="(checkedFilterTags.length > 0 || checkedMaturityTags.length > 0) && showFilters" class="isolate inline-flex">
              <button
                @click="checkedFilterTags = [], checkedMaturityTags = []"
                type="button"
                :class="[
                  'rounded-l-md',
                  'rounded-l-md rounded-r-md',
                  `text-gray-600 hover:text-gray-900`,
                  'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                ]"
              >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>

                <span class="ml-1">Clear Filters</span>
              </button>
            </div>
            <div class="isolate inline-flex">
              <button
                @click="showFilters = !showFilters"
                type="button"
                :class="[
                  'rounded-l-md',
                  'rounded-l-md rounded-r-md',
                  `text-gray-600 hover:text-gray-900`,
                  'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                ]"
              >
                <FilterIcon />
                <span class="ml-1">{{ showFilters ? 'Hide' : 'Show' }} Filters</span>
                <span v-if="checkedFilterTags.length+checkedMaturityTags.length > 0" class="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">{{ checkedFilterTags.length+checkedMaturityTags.length }}</span>
              </button>
            </div>
          </div>
        </div>

        <!-- {{ maturities }} -->
        
        <!-- <div v-if="showFilters" class="border border-gray-200 rounded px-4 py-4 flex flex-wrap bg-white">
          <div class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs text-gray-600 ring-1 ring-inset ring-gray-400 mr-2 my-1 space-x-1">
            <button @click="checkedFilterTags = []">Clear Filters</button>
          </div>
          <div v-for="(tag, index) in tags" :key="index" @click="handleTagFilterClick(tag)" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs text-gray-600 ring-1 ring-inset ring-gray-400 mr-2 my-1 space-x-1 cursor-pointer">
            <input :checked="checkedFilterTags.includes(tag)" :id="`${tag}-${index}`" :aria-describedby="`${tag}-${index}-description`" :name="`${tag}-${index}`" type="checkbox" class="h-3 w-3 rounded border-gray-300 text-indigo-600 -ml-0.5 cursor-pointer" />
            <span>{{ tag }}</span>
          </div>
        </div> -->

        <div v-if="showFilters" class="border border-gray-200 rounded px-4 py-4 bg-white">
          <div class="grid grid-cols-4 grid-flow-row-dense gap-4">
            <div v-if="showMaturityFilters">
              <h3 class="font-medium text-gray-700 mt-2">Maturity</h3>
              <div class="flex flex-wrap mt-2">
                <div v-for="(maturity, index) in maturities" :key="index" @click="handleMaturityFilterClick(maturity)" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs text-gray-600 ring-1 ring-inset ring-gray-400 mr-2 my-1 space-x-1 cursor-pointer">
                  <input :checked="checkedMaturityTags.includes(maturity)" :id="`${maturity}-${index}`" :aria-describedby="`${maturity}-${index}-description`" :name="`${maturity}-${index}`" type="checkbox" class="h-3 w-3 rounded border-gray-300 text-indigo-600 -ml-0.5 cursor-pointer" />
                  <span>{{ stageEnumToString(maturity) }}</span>
                </div>
              </div>
            </div>
            <div v-for="category in Object.keys(TAGS_BY_CATEGORY)" :key="category">
              <h3 class="font-medium text-gray-700 mt-2">{{ category }}</h3>
              <div class="flex flex-wrap mt-2">
                <div v-for="(tag, index) in TAGS_BY_CATEGORY[category]" :key="index" @click="handleTagFilterClick(tag)" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs text-gray-600 ring-1 ring-inset ring-gray-400 mr-2 my-1 space-x-1 cursor-pointer">
                  <input :checked="checkedFilterTags.includes(tag)" :id="`${tag}-${index}`" :aria-describedby="`${tag}-${index}-description`" :name="`${tag}-${index}`" type="checkbox" class="h-3 w-3 rounded border-gray-300 text-indigo-600 -ml-0.5 cursor-pointer" />
                  <span>{{ tag }}</span>
                </div>
              </div>
            </div>
            
            
            
          </div>
        </div>

        <!-- Grid -->
        <ul class="grid grid-cols-2 gap-8 mt-4 mb-9">
          <li 
            v-for="policy, index in sortedPolicies" 
            :key="`policy-${index}`" 
            :class="[
              'border bg-white text-sm rounded-md flex flex-col justify-between',
              policy.getSettings().getMustBeSanitized() ? 'border-red-100' : 'border-gray-200'
              ]">
            <div class="px-5 py-3">
              <div class="flex justify-between space-x-2 items-center font-medium text-gray-700 mb-2 mt-0 flex-grow">
                <h1 class="text-base flex items-center">
                  <span>{{ policy.getInfo().getName() }} </span>
                  <WarningTooltip v-if="policy.getSettings().getMustBeSanitized()" message="Contact Support" class="w-4 h-4"/>
                </h1>
                <span v-if="policy.getInfo().getMaturity() != STAGE.STAGE_STABLE" 
                  :class="[
                    `bg-${stage_color_map[policy.getInfo().getMaturity()]}-50 text-${stage_color_map[policy.getInfo().getMaturity()]}-500 border-${stage_color_map[policy.getInfo().getMaturity()]}-500`,
                    'text-xs font-medium mr-2 px-2.5 py-0.5 rounded border']">
                  {{ enumToString(STAGE, policy.getInfo().getMaturity()).replace("STAGE_","") }}
                </span>
              </div>
              <div class="text-gray-500 w-full mt-4">
                <!-- <p v-if="policy.getName() == 'VeraSafe'">A histogram-based static model to alert users of risky recipients. Historical data not required.</p>
                <p v-if="policy.getName() ==  'Internal Organization Domains'">Known internal domain names are always considered safe.</p> -->
                <p>{{ policy.getInfo().getDescription() }}</p>
                

              </div>
              <div class="mt-4 text-sm text-gray-700">
                <span 
                  v-for="tag in policy.getInfo().getTagsList()" 
                  :key="tag" 
                  :class="[
                    'inline-flex items-center rounded-md bg-gray-50 px-2 py-0.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-400 mr-2 my-0.5'
                  ]"
                >
                  {{ tag }}
                </span>
              </div>
            </div>
            <!-- Action Section -->
            <div :class="[
              policy.getSettings().getMustBeSanitized() ? 'bg-red-200 text-red-700' : 'bg-gray-100 text-gray-700',
              'px-5 h-12 flex items-center justify-between rounded-b-md']">
              <div class="flex items-center space-x-2">
                <button 
                  v-if="policy.getSettings().getCanBeEnabled()"
                  @click="toggleStatus(policy.getStatus(), index)"
                  type="button" 
                  :class="[
                    (policy.getStatus() == STATUS.STATUS_ENABLED) ? 'bg-blue-600' : 'bg-gray-200',
                    'disabled:opacity-25 disabled:pointer-events-none disabled:cursor-default relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                    ]" role="switch" aria-checked="false">
                  <span class="sr-only">Use setting</span>
                  <span :class="[
                      (policy.getStatus() == STATUS.STATUS_ENABLED) ? 'translate-x-3' : 'translate-x-0',
                      'pointer-events-none relative inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]">
                  </span>
                </button>
                <span v-if="policy.getSettings().getCanBeEnabled()" class="text-gray-700">
                  {{ (policy.getStatus() == STATUS.STATUS_ENABLED) ? 'Disable Policy' : 'Enable Policy' }}
                </span>
              </div>
              <div v-if="policy.getSettings().getCanBeReset() || policy.getSettings().getMustBeSanitized()">
                <button type="button" @click="sanitizePolicy(policy, index)" class="flex items-center space-x-0.5 rounded bg-white py-1 pl-1 pr-3 text-sm text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <DefaultTooltip message="This will clear out the database for this policy and disables it." class="w-4 h-4 mr-1"/>
                  <span>Reset Policy</span>
                </button>
              </div>

              <div v-if="policy.getInfo().getMaturity() == STAGE.STAGE_UPCOMING">
                <a type="button" :href="`mailto:support@preava.com?subject=Wait list request for ${policy.getInfo().getName()}!&body=I want to join the waiting list for the ${policy.getInfo().getName()} policy`" target="_blank" class="flex items-center space-x-0.5 rounded bg-white py-1 px-3 text-sm text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Join Waiting List
                </a>
              </div>

              
            </div>
          </li>
        </ul>
        
      </div>
      
      </main>
    </div>

    <div v-if="SESSION_STORE.isWaiting" class="absolute bottom-3 right-5 py-3">
      <span class="border border-gray-100 shadow-md bg-white text-gray-500 text-xs rounded-md px-4 py-2 flex items-center">
        <LoaderIcon class="animate-spin flex-shrink-0 mr-2 h-4 w-4 text-gray-400"/>Please wait...
      </span>
    </div>

    
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div v-if="SESSION_STORE.showErrorMessage" aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-end sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="SESSION_STORE.showErrorMessage" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <!-- <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div> -->
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-400">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>

              <div class="ml-3 w-0 flex-1 pt-0.5">
                <!-- <p class="text-sm font-medium text-gray-900">Error!</p> -->
                <p class="text-sm text-gray-500">{{ SESSION_STORE.errorMessage }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="SESSION_STORE.showErrorMessage = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <!-- <XMarkIcon class="h-5 w-5" aria-hidden="true" /> -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>

  
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div v-if="SESSION_STORE.errorMessage" aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-end sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <!-- <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div> -->
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <!-- <p class="text-sm font-medium text-gray-900">Error!</p> -->
                <p class="text-sm text-gray-500">{{ SESSION_STORE.errorMessage }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="show = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <!-- <XMarkIcon class="h-5 w-5" aria-hidden="true" /> -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  
</template>
<script setup>

// Adapter and User classes, etc.
import { activate } from '@/adapter'
import { Role as ROLE } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { v4 } from "uuid";
import ROUTER from "@/router";

/**
 * Import the necessary module components
 */
import { ref, computed } from "vue";
import DefaultHeader from "@/components/header/DefaultHeader"; // from global
import DefaultSidebar from "@/components/sidebar/DefaultSidebar"; // from global
import LoaderIcon from '@/assets/icons/LoaderIcon.vue';
import DefaultNotification from "@/components/notification/DefaultNotification";
import DefaultTooltip from "@/components/tooltip/DefaultTooltip";
import WarningTooltip from "@/components/tooltip/WarningTooltip";
import FilterIcon from "@/modules/rules/icons/FilterIcon.vue";
// import EmailDomainInput from "../components/EmailDomainInput.vue";
// import GroupHeaderUI from "../components/GroupHeaderUI.vue";
// import ItemDisplay from "../components/ItemDisplay.vue";

/**
 * Import Preava dependencies
 */
import { GRPC_ERROR, enumToString } from "@/utilities";
import { Stage as STAGE, Status as STATUS } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

/**
 * Import Store dependencies
 */
import { useSessionStore, usePoliciesStore } from "@/modules/v2/store";
const POLICIES_STORE = usePoliciesStore();
const SESSION_STORE = useSessionStore();

const stage_color_map = {
  0: 'red',
  1: 'orange',
  2: 'purple',
  3: 'blue',
  4: 'gray',
}


const ui_maturity_order = [
  STAGE.STAGE_STABLE,
  STAGE.STAGE_BETA,
  STAGE.STAGE_ALPHA,
  STAGE.STAGE_EXPERIMENTAL,
  STAGE.STAGE_UPCOMING,
]
// STAGE_STABLE: GRAY


var TAGS_BY_CATEGORY = { 
  "Business Purpose": [
    "Compliance",
    "DLP",
    "Reputation Preservation",
    "Risky Recipients",
    "Safe Recipients" ], 
  
  "Data Inputs": [
    "Attachment Contents",
    "Attachment Filename",
    "Body",
    "Domain Name",
    "Email Address",
    "Sender",
    "Subject" ], 
  
  "Historical Data": [
    "Historical Data",
    "No Historical Data" ],
  
  "Modeling": [
    "Heuristic",
    "Histogram",
    "Learning",
    "Static" ],
  
  "Region": [
    "US",
    "Worldwide" ],
  
  "Regulation": [
    "FINRA"
  ],
  
  "Other": [
    "Override" ]
};

const showMaturityFilters = computed( () => {
  if(maturities.value.length == 1 && maturities.value[0] == STAGE.STAGE_STABLE){
    return false
  }else{
    return true
  }
})

const stageEnumToString = (maturity) => {
  let _stage = enumToString(STAGE, maturity).replace('STAGE_', '').toLowerCase()
  return _stage.charAt(0).toUpperCase() + _stage.slice(1);
}

/**
 * @todo
 * These are initial default values.
 */
// const DEFAULT_GROUP = 'DEFAULT_GROUP';

/**
 * In here, we get the index of the specific item and toggle the 'enabled' setting.
 * We then push the change to the backend.
 */
 const toggleStatus = async (value, index) => {
  SESSION_STORE.isWaiting = true
  
  const itemsList = POLICIES_STORE.smartpolicies.getPoliciesList()
  const itemsListIndex = itemsList.findIndex(e => e.getInfo().getName() == sortedPolicies.value[index].getInfo().getName())

  let name = itemsList[itemsListIndex].getInfo().getName();
  let status = (itemsList[itemsListIndex].getStatus() == STATUS.STATUS_ENABLED) ? STATUS.STATUS_DISABLED : STATUS.STATUS_ENABLED;

  itemsList[itemsListIndex].setStatus(status);
  
  let result = await POLICIES_STORE.pushSmartPoliciesChanges(SESSION_STORE.traceId, {name: name, status: status});
  if(!result){
    // revert back changes
    itemsList[itemsListIndex].setStatus(!status);
  }

  await fetchData();

  SESSION_STORE.isWaiting = false;
  
}

const tags = ref([]);
const maturities = ref([]);
const tags_color_map = ref(null);
const maturity_color_map = ref(null);
const showFilters = ref(false);
const checkedFilterTags = ref([]);
const checkedMaturityTags = ref([]);

const sortedPolicies = computed(() => {

  /**
   * first thing to do here is to filter the policies
   * based on their maturity. If there were no maturity tags 
   * selected, thenwe return the list of policies as is. 
   * If there are maturity filters added, then we filter it first.
   */


  // make sure that the policies list is not empty.
  if(POLICIES_STORE.smartpolicies){
    
    var policies = POLICIES_STORE.smartpolicies.getPoliciesList()      
    
    /**
     * Before the we do any form of filtering to the 
     * policies and eventually displayed to the user,
     * they will be sorted out first by maturity in this
     * specific order:
     * 
     * const ui_maturity_order = [
     *  STAGE.STAGE_STABLE,
     *  STAGE.STAGE_BETA,
     *  STAGE.STAGE_ALPHA,
     *  STAGE.STAGE_EXPERIMENTAL,
     *  STAGE.STAGE_UPCOMING,
     * ]
     */
    
    var sortedPoliciesByMaturity = new Array();
    ui_maturity_order.forEach( maturity => {
      let filteredPoliciesByMaturity = policies.filter( policy => policy.getInfo().getMaturity() == maturity);
      sortedPoliciesByMaturity = sortedPoliciesByMaturity.concat(filteredPoliciesByMaturity)
    });

    /**
     * sortedPoliciesByMaturity now contains the policies above
     * sorted by the preferred order of maturity. Next, we start
     * any filtering. We will be looking at the maturity filters 
     * first, selected by the user. 
     */

    /**
     * See if they selected any maturity tags for filtering.
     * If yes, loop over the sortedPoliciesByMaturity and see 
     * if their maturity was selected by the user. If true, 
     * add this policy to a temporary array. Once done, replace
     * sortedPoliciesByMaturity with the updated value stored 
     * in temporaryArray.
     */
    
    if(checkedMaturityTags.value.length > 0){
      let temporaryArray = new Array();
      sortedPoliciesByMaturity.forEach( policy => {
        if(checkedMaturityTags.value.includes(policy.getInfo().getMaturity())){
          temporaryArray.push(policy)
        }
      });
      sortedPoliciesByMaturity = temporaryArray
    }

    /**
     * At this point, sortedPoliciesByMaturity now has the 
     * filtered policies by maturity if they selected a maturity
     * tag filter or just the original sortedPoliciesByMaturity
     * if they did not select anything. Up next, we simply filter
     * this sortedPoliciesByMaturity array with the normal filter tags;
     * given that the user checked a filter tag. We'll do the same
     * thing with the above.
     * 
     * How: we make sure that ALL checkedFilterTags are present within
     * a policy's tags list. This is accomplished by using Array.every() 
     * where every is applied on checkedFilterTags.
     */
    
    if(checkedFilterTags.value.length > 0){
      let temporaryArray = new Array();
      sortedPoliciesByMaturity.forEach( policy => {
        let policyTagsList = policy.getInfo().getTagsList();
        if(checkedFilterTags.value.every( tag => policyTagsList.includes(tag))){
          temporaryArray.push(policy)
        }
      })
      sortedPoliciesByMaturity = temporaryArray
    }

    return sortedPoliciesByMaturity

  }
  else return [] // return an empty array by default

})

const handleTagFilterClick = (tag) => {
  console.log("executing handleTagFilterClick!");
  if(checkedFilterTags.value.includes(tag)){
    let i = checkedFilterTags.value.findIndex( _tag => _tag == tag);
    checkedFilterTags.value.splice(i, 1)
  }else{
    checkedFilterTags.value.push(tag)
  }
  console.log(checkedFilterTags.value)
}

const handleMaturityFilterClick = (maturity) => {
  console.log("executing handleMaturityFilterClick!");
  if(checkedMaturityTags.value.includes(maturity)){
    let i = checkedMaturityTags.value.findIndex( _maturity => _maturity == maturity);
    checkedMaturityTags.value.splice(i, 1)
  }else{
    checkedMaturityTags.value.push(maturity)
  }
  console.log(checkedMaturityTags.value)
}

/**
 * @TODO
 * Move this to the router and use store for this.
 * Make this reusable
 */
 const adminGuard = async () => {
  return new Promise(async (resolve, reject) => {
    activate(v4())
      .then( async result => {
        console.log({result})
        console.log(result.getRole())
        if(result.getRole() === ROLE.ROLE_ADMIN){
          // If admin, proceed normally
          console.log("%cYou have the right to view this page.", "color: #B4D455")
          resolve();
        }else if(result.getRole() === ROLE.ROLE_USER){
          // Else if user, redirect immediately to /endpoints page
          console.log("%cYou don't have the right to view this page. Redirecting...", "color: #FE2020")
          ROUTER.push({ path: '/integrations' })
        }
      })          
  });
}

/**
 * In here, we initialize the view.
 * First we initialize the session and create a new trace Id.
 * Afterwards, we fetch the smart policies from the backend.
 * Once fetched, the UI will automatically display them.
 */
const initialize = async () => {
  SESSION_STORE.isInitializing = true;

  await adminGuard();
  
  console.log("%cIntializing Smart Policies Tab", "color:#b4d455");

  try {
    await SESSION_STORE.createNewTraceId();
    let result = await POLICIES_STORE.fetchSmartPolicies(SESSION_STORE.traceId);

    /** Aggregate Tags */
    let policies = [];
    if(POLICIES_STORE.smartpolicies){
      policies = POLICIES_STORE.smartpolicies.getPoliciesList();
    }
    
    policies.forEach( e=> {
      console.log(e.getInfo().toObject());
      let _arrTags = e.getInfo().getTagsList();
      tags.value = [...tags.value, ..._arrTags]

      maturities.value.push(e.getInfo().getMaturity());
      maturities.value = [...new Set(maturities.value)].toSorted().reverse();
    });

    // remove duplicates and sort array
    tags.value = [...new Set(tags.value)].toSorted();

    tags_color_map.value = applyTagColors(tags.value);
    console.log('tags_color_map', tags_color_map.value);

    let _maturity = [];
    for (var key in STAGE) { _maturity.push(STAGE[key]) }
    maturity_color_map.value = applyTagColors(_maturity);
    console.log(maturity_color_map.value)


  } catch (err) {
    /**
     * Handle this error in the UI. Set the error message in the store,
     * that way other components can access it.
     */
    SESSION_STORE.errorMessage = `[${enumToString(GRPC_ERROR, err.code)}]: ${
      err.message
    }`;
  }
  SESSION_STORE.isInitializing = false;
  SESSION_STORE.isWaiting = false;
};

initialize();

const fetchData = async () => {
  SESSION_STORE.isWaiting = true;

  console.log("%cIntializing Smart Policies Tab", "color:#b4d455");

  try {
    await SESSION_STORE.createNewTraceId();
    let result = await POLICIES_STORE.fetchSmartPolicies(SESSION_STORE.traceId);

    /** Aggregate Tags */
    let policies = [];
    if(POLICIES_STORE.smartpolicies){
      policies = POLICIES_STORE.smartpolicies.getPoliciesList();
    }
    
    policies.forEach( e=> {
      console.log(e.getInfo().toObject());
      let _arrTags = e.getInfo().getTagsList();
      tags.value = [...tags.value, ..._arrTags]

      maturities.value.push(e.getInfo().getMaturity());
      maturities.value = [...new Set(maturities.value)].toSorted().reverse();
    });

    // remove duplicates and sort array
    tags.value = [...new Set(tags.value)].toSorted();

    tags_color_map.value = applyTagColors(tags.value);
    console.log('tags_color_map', tags_color_map.value);

    let _maturity = [];
    for (var key in STAGE) { _maturity.push(STAGE[key]) }
    maturity_color_map.value = applyTagColors(_maturity);
    console.log(maturity_color_map.value)


  } catch (err) {
    /**
     * Handle this error in the UI. Set the error message in the store,
     * that way other components can access it.
     */
    SESSION_STORE.errorMessage = `[${enumToString(GRPC_ERROR, err.code)}]: ${
      err.message
    }`;
  }
  SESSION_STORE.isWaiting = false;
};

const sanitizePolicy = async (policy, index) => {

  SESSION_STORE.isWaiting = true;
  console.log("%cSanitizing Smart Policy: ", "color:#b4d455");
  console.log(name)

  try {
    await POLICIES_STORE.pushSanitizePolicy(SESSION_STORE.traceId, policy.getInfo().getName());
    // toggleStatus(policy.getStatus(), index)
    
    await fetchData();
    

    SESSION_STORE.isWaiting = false;
  } catch (err) {
    /**
     * Handle this error in the UI. Set the error message in the store,
     * that way other components can access it.
     */
    SESSION_STORE.errorMessage = `[${enumToString(GRPC_ERROR, err.code)}]: ${
      err.message
    }`;

  }
}

function applyTagColors(tags) {
  // Aggregate the tags
  const aggregatedTags = tags.reduce((accumulator, tag) => {
    if (!accumulator[tag]) {
      accumulator[tag] = 1;
    } else {
      accumulator[tag]++;
    }
    return accumulator;
  }, {});

  // Sort the tags alphabetically
  const sortedTags = Object.keys(aggregatedTags).sort();

  // Create a map of tags/colors
  const tagColors = {};
  const colors = ['purple', 'lime', 'red', 'blue', 'orange', 'lime', 'yellow'];
  // const colors = ['gray'];
  sortedTags.forEach((tag, index) => {
    const colorIndex = index % colors.length;
    tagColors[tag] = colors[colorIndex];
  });

  return tagColors;
}
</script>